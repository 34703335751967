<template>
  <div :class="taille">
    <div class="row">
      <div class="col-md-4 mx-auto my-4">
        <form class="form-inline">
          <div class="form-group mr-2">
            <label for="">Année:</label>
            <input type="date" 
                   class="form-control ml-2"
                   v-model="annee">
          </div>
          <div class="form-group">
            <button type="button"
                    class="btn btn-info"
                    @click="changeYear()">
              Rechercher
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <table class="table">
          <thead>
            <tr>
              <th rowspan="2"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;">Banques</th>
              <th rowspan="2"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;">Montants</th>
              <th colspan="12"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;">Réalisations</th>
              <th rowspan="2"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;width: 8%;">Totaux par rubriques</th>
            </tr>
            <tr>
              <th class="text-right">janv.-{{demi_annee}}</th>
              <th class="text-right">févr.-{{demi_annee}}</th>
              <th class="text-right">mars-{{demi_annee}}</th>
              <th class="text-right">avr.-{{demi_annee}}</th>
              <th class="text-right">mai-{{demi_annee}}</th>
              <th class="text-right">juin-{{demi_annee}}</th>
              <th class="text-right">juil.-{{demi_annee}}</th>
              <th class="text-right">août-{{demi_annee}}</th>
              <th class="text-right">sept.-{{demi_annee}}</th>
              <th class="text-right">oct.-{{demi_annee}}</th>
              <th class="text-right">nov.-{{demi_annee}}</th>
              <th class="text-right">déc.-{{demi_annee}}</th>
            </tr>
          </thead>
          <tbody v-for="(bank,bkey) in listBank"
                 :key="bkey">
            <template v-for="(remboursement,rkey) in remboursement.donnees">
              <tr :key="rkey" 
                  v-if="bank.banque==remboursement.banque">
                <td class="tht-blue text-center">{{ remboursement.banque }}</td>
                <td class="tht-blue">{{ Math.round(Number(remboursement.montant_emprunt)).toLocaleString() }}</td>
                <td class="text-right"
                    v-for="(montant,mtkey) in remboursement.montant_rembourse_principal[0]"
                    :key="mtkey">{{Math.round(montant).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(remboursement.total_remboursement)).toLocaleString()}}</td>
              </tr>
            </template>
            <tr>
              <td colspan="2"
                  class="tht-dark-blue text-center">
                Sous-Total {{ bank.banque }}
              </td>
              <td class="tht-dark-blue text-right"
                  v-for="(total,stkey) in bank.sous_total"
                  :key="stkey">{{ Math.round(total).toLocaleString() }}</td>
            </tr>
          </tbody>
          <!-- <tbody>
            <tr v-for="(gage,gakey) in listGage"
                :key="gakey">
              <td colspan="2"
                  class="tht-dark-blue">Dettes assises {{ gage.ressources_gagees }}</td>
              <td class="tht-dark-blue text-right"
                  v-for="(soustotal,soutkey) in gage.sous_total"
                  :key="soutkey">{{ Math.round(soustotal).toLocaleString() }}</td>
            </tr>
          </tbody> -->
          <tbody>
            <tr>
              <td colspan="2"
                  class="tht-blue text-center">
                Total mensuel
              </td>
              <td v-for="(mois,mokey) in total_mensuel"
                  :key="mokey"
                  class="text-right">{{ Math.round(mois).toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>
@import '../leveeDeFonds.css';
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import utils from "@/assets/js/utils"

export default {
  name: "Remboursement",
  props:["year"],
  components:{
  },
  data: () => ({
    taille:"chisai-r",
    annee:new Date().getFullYear()+"-01-01",
    listBank:[],
    listGage:[],
    demi_annee:"",
    total_mensuel:[0,0,0,0,0,0,0,0,0,0,0,0,0]
  }),
  watch: {
    remboursement(){
      if (this.remboursement) {
        // Récupère la liste des banques contenu dans la réponse du serveur
        this.listBank=[]
        this.remboursement.donnees.forEach(remboursement => {
          if (!this.listBank.includes(remboursement.banque)) {
            this.listBank.push(remboursement.banque)
          }
        })
        // transformation des lignes de la liste en objet pour y inclure les sous_totaux
        for (let index = 0; index < this.listBank.length; index++) {
          this.listBank[index] = {banque:this.listBank[index],sous_total:[0,0,0,0,0,0,0,0,0,0,0,0,0]}
        }
        // remplissage des sous_totaux
        for (let index = 0; index < this.listBank.length; index++) {

          this.remboursement.donnees.forEach(remboursement => {
            if (this.listBank[index].banque==remboursement.banque) {
              // console.log("in")
              for (let compteur = 0; compteur < remboursement.montant_rembourse_principal[0].length; compteur++) {
                this.listBank[index].sous_total[compteur]+=remboursement.montant_rembourse_principal[0][compteur]
                this.total_mensuel[compteur]+=Number(remboursement.montant_rembourse_principal[0][compteur])
              }
              this.listBank[index].sous_total[this.listBank[index].sous_total.length-1]+=remboursement.total_remboursement
              this.total_mensuel[this.total_mensuel.length-1]+=remboursement.total_remboursement
            }
          })
        }
        console.log(this.total_mensuel)
        // Récupère la liste des gages contenu dans la réponse du serveur
        this.listGage=[]
        this.remboursement.donnees.forEach(remboursement => {
          if (!this.listGage.includes(remboursement.ressources_gagees)) {
            this.listGage.push(remboursement.ressources_gagees)
          }
        })
        // transformation des lignes de la liste en objet pour y inclure les sous_totaux
        for (let index = 0; index < this.listGage.length; index++) {
          this.listGage[index] = {ressources_gagees:this.listGage[index],sous_total:[0,0,0,0,0,0,0,0,0,0,0,0,0]}
        }
        // remplissage des sous_totaux
        for (let index = 0; index < this.listGage.length; index++) {
          this.remboursement.donnees.forEach(remboursement => {
            if (this.listGage[index].ressources_gagees==remboursement.ressources_gagees) {
              // console.log("in")
              for (let compteur = 0; compteur < remboursement.montant_rembourse_principal[0].length; compteur++) {
                this.listGage[index].sous_total[compteur]+=remboursement.montant_rembourse_principal[0][compteur]
              }
              this.listGage[index].sous_total[this.listGage[index].sous_total.length-1]+=remboursement.total_remboursement
            }
          })
        }
        // console.log(this.listGage)
      }
    }
  },
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.listExtensionFiles = utils.formaFichierAccepte
    this.annee=this.year.annee_debut
    this.demi_annee=this.annee.split("-")
    this.demi_annee=this.demi_annee[0].split("")
    this.demi_annee=this.demi_annee[2]+this.demi_annee[3]
    // console.log(this.demi_annee)
    this.getRemboursement({annee_debut:this.year.annee_debut, annee_fin:this.year.annee_fin, annee:this.year.annee_debut})
  },
  computed: {
    ...mapGetters(["remboursement","msgFailRemboursement",])
  },
  methods: {
    ...mapActions(["getRemboursement","saveFile", "pageLevees","importDette"]),
    ...mapMutations(["setRemboursement","setMsgFailRemboursement",]),
    changeYear(){
      this.demi_annee=this.annee.split("-")
      this.demi_annee=this.demi_annee[0].split("")
      this.demi_annee=this.demi_annee[2]+this.demi_annee[3]
      this.setRemboursement("")
      this.getRemboursement({annee_debut:this.year.annee_debut,annee_fin:this.year.annee_fin,annee:this.annee})
    }
  }
}
</script>
