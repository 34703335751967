<template>
  <div :class="taille">
    <div class="row">
      <div class="col-md-5 mx-auto my-4">
        <form class="form-inline">
          <div class="form-group mr-2">
            <label for="">Date:</label>
            <input type="date" 
                   class="form-control ml-2"
                   v-model="annee">
          </div>
          <div class="form-group">
            <button type="button"
                    class="btn btn-info"
                    @click="changeYear()">
              Rechercher
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <table class="table">
          <thead>
            
            <tr>
              <th rowspan="2"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;width: 8%;">Banques</th>
              <th rowspan="2"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;">Dettes</th>
              <th rowspan="2"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;width: 15%;">Remboursements à effectuer sur l'exercice {{full_annee}} de (Principal + Intérêt)</th>
              <th colspan="3"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;">Remboursements effectués sur {{ full_annee }}</th>
              <th rowspan="2"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;width: 11%;">Reste à rembourser sur {{ full_annee }}</th>
            </tr>
            <tr>
              <th class="text-center">Principal</th>
              <th class="text-center">Intérêt</th>
              <th class="text-center">Total au </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(annuel,ankey) in syntheseAnnuelle.donnees"
                :key="ankey">
              <td class="tht-blue text-center">{{annuel.banque}}</td>
              <td class="text-right">{{ Math.round(annuel.montant_emprunt).toLocaleString() }}</td>
              <td class="text-right">{{Math.round(annuel.montant_rembourse_a).toLocaleString()}}</td>
              <td class="text-right">
                {{Math.round(annuel.capital_rembourse_b).toLocaleString()}}</td>
              <td class="text-right">
                {{Math.round(annuel.interets_a_payer_b).toLocaleString()}}</td>
              <td class="text-right">
                {{Math.round(annuel.montant_rembourse_b).toLocaleString()}}</td>
              <!-- <template v-for="i in annuel.periode[0].length">
                <td class="text-right"
                    :key="'prin'+i">{{Math.round(annuel.montant_rembourse_principal[0][i]).toLocaleString()}}</td>
                <td class="text-right"
                    :key="'int'+i">{{Math.round(annuel.montant_rembourse_interet[0][i]).toLocaleString()}}</td>
                <td class="text-right"
                    :key="'tot'+i">{{Math.round(annuel.montant_rembourse_principal[0][i]+annuel.montant_rembourse_interet[0][i]).toLocaleString()}}</td>
              </template> -->
              <td class="text-right">{{Math.round(annuel.reste_a_rembourse).toLocaleString()}}</td>
            </tr>
            <tr>
              <td colspan="2"
                  class="tht-dark-blue text-center">Totaux</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.total_remboursement).toLocaleString()}}</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.principal).toLocaleString()}}</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.interet).toLocaleString()}}</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.total).toLocaleString()}}</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.total_reste_a_payer).toLocaleString()}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>
@import '../leveeDeFonds.css';
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import utils from "@/assets/js/utils"


export default {
  name: "SyntheseAnnuelle",
  props:["year"],
  components:{
  },
  data: () => ({
    taille:"chisai-a",
    annee:new Date().getFullYear()+"-01-01",
    full_annee:new Date().getFullYear(),
    totaux:{
      principal:0,
      interet:0,
      total:0,
      total_remboursement:0,
      total_reste_a_payer:0,
    }
  }),
  watch: {
    syntheseAnnuelle(){
      if (this.syntheseAnnuelle) {
        this.totaux={
          principal:0,
          interet:0,
          total:0,
          total_remboursement:0,
          total_reste_a_payer:0,
        }
        this.syntheseAnnuelle.donnees.forEach(synthese => {
          
          this.totaux.principal += synthese.capital_rembourse_b
          this.totaux.interet += synthese.interets_a_payer_b
          this.totaux.total += synthese.montant_rembourse_b
           
          this.totaux.total_remboursement += synthese.montant_rembourse_a
          this.totaux.total_reste_a_payer += synthese.reste_a_rembourse
        })
        console.log(this.totaux)
      }
    }
  },
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.listExtensionFiles = utils.formaFichierAccepte
    this.annee=this.year.annee_debut
    this.full_annee=this.annee.split("-")
    this.full_annee=this.full_annee[0]
    this.getSyntheseAnnuelle({annee_debut:this.year.annee_debut, annee_fin:this.year.annee_fin, annee:this.year.annee_debut})
    
  },
  computed: {
    ...mapGetters(["syntheseAnnuelle","msgFailSyntheseAnnuelle",])
  },
  methods: {
    ...mapActions(["getSyntheseAnnuelle"]),
    ...mapMutations(["setMsgFailSyntheseAnnuelle","setSyntheseAnnuelle",]),
    changeYear(){
      this.setSyntheseAnnuelle("")
      this.getSyntheseAnnuelle({annee_debut:this.year.annee_debut,annee_fin:this.year.annee_fin,annee:this.annee})
      this.full_annee=this.annee.split("-")
      this.full_annee=this.full_annee[0]
    }
  }
}
</script>
