<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Dettes ></span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Suivi des emprunts
          </div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row mb-2">
      <!-- <span> <span class="text-info"> Analyses des charges  &rarr;  </span>  La repartition de ce montant par catégorie de charges</span>  -->
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>

    <ul class="nav nav-tabs"
        id="myTab"
        role="tablist">
      <li class="nav-item">
        <a class="nav-link active"
           id="home-tab"
           data-toggle="tab"
           href="#home"
           role="tab"
           aria-controls="home"
           aria-selected="true"
           @click="choix='fiche'">Fiche technique</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="presentation-tab"
           data-toggle="tab"
           href="#presentation"
           role="tab"
           aria-controls="presentation"
           aria-selected="true"
           @click="choix='general'">Synthèse générale</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="profile-tab"
           data-toggle="tab"
           href="#profile"
           role="tab"
           aria-controls="profile"
           aria-selected="false"
           @click="choix='remboursement'">Remboursement mensuel</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           id="contact-tab"
           data-toggle="tab"
           href="#contact"
           role="tab"
           aria-controls="contact"
           aria-selected="false"
           @click="choix='annuelle'">Synthèse annuelle</a>
      </li>
    </ul>
    <div class="tab-content" 
         id="myTabContent">
      <div class="tab-pane fade show active" 
           id="home" 
           role="tabpanel" 
           aria-labelledby="home-tab">
        <FicheTechnique v-if="choix=='fiche'"
                        :year="year"/>
      </div>
      <div class="tab-pane fade" 
           id="presentation" 
           role="tabpanel" 
           aria-labelledby="presentation-tab">
        <SyntheseGenerale v-if="choix=='general'"
                          :year="year"/>
      </div>
      <div class="tab-pane fade" 
           id="profile" 
           role="tabpanel" 
           aria-labelledby="profile-tab">
        <Remboursement v-if="choix=='remboursement'"
                       :year="year"/>
      </div>
      <div class="tab-pane fade" 
           id="contact" 
           role="tabpanel" 
           aria-labelledby="contact-tab">
        <SyntheseAnnuelle v-if="choix=='annuelle'"
                          :year="year"/>
      </div>
    </div>
    
    
  </div>
</template>
<style>
@import './leveeDeFonds.css';
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import utils from "@/assets/js/utils"
import Notif from "@/components/shared/Toast"
import FicheTechnique from "./SuiviEtatEmprunt/FicheTechnique.vue"
import SyntheseGenerale from "./SuiviEtatEmprunt/SyntheseGenerale.vue"
import Remboursement from "./SuiviEtatEmprunt/Remboursement.vue"
import SyntheseAnnuelle from "./SuiviEtatEmprunt/SyntheseAnnuelle.vue"
export default {
  name: "SuiviEtatEmprunts",
  components:{
    Notif,
    FicheTechnique,
    SyntheseGenerale,
    SyntheseAnnuelle,
    Remboursement
  },
  data: () => ({
    year:{
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
      periode:"01-01 au 31-12 " + new Date().getFullYear()
    },
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    periode:"01-01 au 31-12 " + new Date().getFullYear(),
    choix:"fiche",
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
  }),
  watch: {
    
  },
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.listExtensionFiles = utils.formaFichierAccepte

  },
  computed: {
    ...mapGetters(["leveefond","files", "errorsMsg","successLevee","errorsLevee"])
  },
  methods: {
    ...mapActions(["leveeFonds","saveFile", "pageLevees","importDette"]),
    ...mapMutations(["setLeveeFond","setSuccess","setError","setFiles"]),
    changeYear(){
      
      var split_annee_debut=this.annee_debut.split("-")
      var split_annee_fin=this.annee_fin.split("-")
      if (Number(split_annee_fin[0])-Number(split_annee_debut[0])>10) {
        this.notif.message = "La périone ne doit pas excéder 10 ans"
        this.notif.type = "toast-warning"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = "desactivated"
          }.bind(this),
          3000
        )
      }else{
        this.year.annee_debut=this.annee_debut
        this.year.annee_fin=this.annee_fin
        if (split_annee_debut[0] == split_annee_fin[0]) {
          this.periode= split_annee_debut[2]+"-"+split_annee_debut[1]+" au "+split_annee_fin[2]+"-"+split_annee_fin[1]+"-"+split_annee_fin[0]
        }else{
        
          this.periode= split_annee_debut[2]+"-"+split_annee_debut[1]+"-"+split_annee_debut[0]+" au "+split_annee_fin[2]+"-"+split_annee_fin[1]+"-"+split_annee_fin[0]
        }
        this.year.periode=this.periode
        var choice=this.choix
        this.choix=""
        setTimeout(
          function() {
            this.choix=choice
          }.bind(this),
          300
        )
      }
      
    },
    selection(choix){
      this.choix=""
      setTimeout(
        function() {
          this.choix=choix
        }.bind(this),
        300
      )
    }
  }
}
</script>
