<template>
  <div class="heikin-f">
    <div class="row mt-3">
      <div class="col-md-8">
        <form class="form-inline mx-auto">
          <div class="form-group">
            <label for="">Banque:</label>
            <select class="form-control ml-2" 
                    name="" 
                    id=""
                    v-model="parametre.banque">
              <option value="">Choisissez une banque</option>
              <option v-for="(banque, bkey) in banques.donnees"
                      :key="bkey"
                      :value="banque.sigle">{{ banque.sigle }}</option>
            </select>
          </div>
          <div class="form-group mx-4">
            <label for="">Année:</label>
            <input type="date"
                   class="form-control ml-2" 
                   name="" 
                   id="" 
                   v-model="parametre.annee_filtre">
          </div>
          <div class="form-group">
            <label for="">Date de mise en place:</label>
            <input type="date"
                   class="form-control ml-2" 
                   name="" 
                   id="" 
                   v-model="parametre.date_mise_place">
          </div>
          <div class="form-group ml-3">
            <label for="">Montant de l'emprunt:</label>
            <input type="text"
                   class="form-control ml-2" 
                   name="" 
                   id=""
                   v-model="parametre.montant_emprunt">
          </div>
          <div class="form-group ml-4">
            <button type="button"
                    class="btn btn-info"
                    @click="research()">Rechercher</button>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-3">
      <table class="table">
        <thead>
          <tr>
            <th class="tht-blue">Banques</th>
            <th class="tht-blue">Nature du prêt</th>
            <th class="tht-blue">Objet du prêt</th>
            <th class="tht-blue">Garantie accordée</th>
            <th class="tht-blue">Nature de la garantie</th>
            <th class="tht-blue">Montant de l'emprunt</th>
            <th class="tht-blue">Montant des tirages</th>
            <th class="tht-blue">Date de mise en place</th>
            <th class="tht-blue">Date de fin de remboursement</th>
            <th class="tht-blue">Durée de remboursement</th>
            <th class="tht-blue">Périodicité</th>
            <th class="tht-blue">Période de différé en assurance</th>
            <th class="tht-blue">Période de différé en principal</th>
            <th class="tht-blue">Période de différé en intérêt</th>
            <th class="tht-blue">Période de différé en TOB</th>
            <th class="tht-blue">Maturité</th>
            <th class="tht-blue">Taux d'intérêt</th>
            <th class="tht-blue">Montant remboursé en intérêt</th>
            <th class="tht-blue">Montant remboursé en principal</th>
            <th class="tht-blue">total remboursement</th>
            <th class="tht-blue">Reste à payer</th>
            <th class="tht-blue">Reste à payer en remboursement anticipé</th>
          </tr>
          
        </thead>
        <tbody>
          <tr v-for="(fiche,fikey) in ficheTechnique.donnees"
              :key="fikey">
            <td>{{ fiche.preteur_denomination }}</td>
            <td>{{ fiche.nature_pret }}</td>
            <td>{{ fiche.objet_pret }}</td>
            <td>{{ fiche.garantie_accordee }}</td>
            <td>{{ fiche.nature_garantie }}</td>
            <td class="text-right">{{ Math.round(fiche.montant_pret).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(fiche.montant_tirages).toLocaleString() }}</td>
            <td>{{ fiche.date_mise_place }}</td>
            <td>{{ fiche.date_fin_remboursement }}</td>
            <td>{{ fiche.duree_remboursement }}</td>
            <td>{{ fiche.periodicite }}</td>
            <td>{{ fiche.periode_differee_en_assurance }}</td>
            <td>{{ fiche.periode_differee_en_princial }}</td>
            <td>{{ fiche.periode_differee_en_interet }}</td>
            <td>{{ fiche.periode_differee_en_tob }}</td>
            <td>{{ fiche.maturite }}</td>
            <td>{{ fiche.taux_interet }}</td>
            <td class="text-right">{{ Math.round(fiche.interets_a_payer).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(fiche.capital_rembourse).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(fiche.capital_rembourse + fiche.interets_a_payer).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(fiche.capital_restant_du).toLocaleString() }}</td>
            <td class="text-right">{{ Math.round(fiche.reste_a_payer_remboursement_anticipe).toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-md-5">
        <table class="table">
          <thead>
            <tr>
              <th class="tht-dark-blue text-center">TOTAUX</th>
              <th class="tht-blue text-center">SIEGE</th>
              <th class="tht-blue text-center">PEAGE</th>
              <th class="tht-blue text-center">PESAGE</th>
              <th class="tht-blue text-center">AUTRE</th>
              <th class="tht-blue text-center">GENERAL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="tht-dark-blue">Remboursement</td>
              <td class="text-right"
                  v-for="(remboursement,remkey) in totaux.total_remboursement"
                  :key="remkey">{{ Math.round(remboursement).toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="tht-dark-blue">Reste à payer</td>
              <td class="text-right"
                  v-for="(reste,reskey) in totaux.reste_a_payer"
                  :key="reskey">{{ Math.round(reste).toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="tht-dark-blue">Reste à payer en remboursement anticipé</td>
              <td class="text-right"
                  v-for="(anticipe,ankey) in totaux.reste_a_payer_remboursement_anticipe"
                  :key="ankey">{{ Math.round(anticipe).toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>
/* @import '../leveeDeFonds.css'; */
/* form.form-inline input,
form.form-inline select{
  font-size: 0.8em;
} */
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
// import utils from "@/assets/js/utils"

export default {
  name: "FicheTechnique",
  props:["year"],
  components:{
  },
  data: () => ({
    taille:"chisai",
    parametre:{
      date_debut:"",
      date_fin:"",
      banque:"",
      montant_emprunt:"",
      date_mise_place:"",
      annee_filtre:""
    },
    duree:0,
    totaux:{
      total_remboursement:[0,0,0,0,0],
      reste_a_payer:[0,0,0,0,0],
      reste_a_payer_remboursement_anticipe:[0,0,0,0,0]
    }
  }),
  watch: {
    ficheTechnique(){
      if (this.ficheTechnique) {
        this.totaux={
          total_remboursement:[0,0,0,0,0],
          reste_a_payer:[0,0,0,0,0],
          reste_a_payer_remboursement_anticipe:[0,0,0,0,0]
        }
        
        this.ficheTechnique.donnees.forEach(fiche => {
          if (fiche.base=="SIEGE") {
            this.totaux.total_remboursement[0]+=Number(fiche.capital_rembourse+fiche.interets_a_payer)
            this.totaux.reste_a_payer[0]+=Number(fiche.capital_restant_du)
            this.totaux.reste_a_payer_remboursement_anticipe[0]+=Number(fiche.reste_a_payer_remboursement_anticipe)
          }
          if (fiche.base=="PEAGE") {
            this.totaux.total_remboursement[1]+=Number(fiche.capital_rembourse+fiche.interets_a_payer)
            this.totaux.reste_a_payer[1]+=Number(fiche.capital_restant_du)
            this.totaux.reste_a_payer_remboursement_anticipe[1]+=Number(fiche.reste_a_payer_remboursement_anticipe)
          }
          if (fiche.base=="PEASGE") {
            this.totaux.total_remboursement[2]+=Number(fiche.capital_rembourse+fiche.interets_a_payer)
            this.totaux.reste_a_payer[2]+=Number(fiche.capital_restant_du)
            this.totaux.reste_a_payer_remboursement_anticipe[2]+=Number(fiche.reste_a_payer_remboursement_anticipe)
          }
          if (fiche.base=="AUTRE") {
            this.totaux.total_remboursement[3]+=Number(fiche.capital_rembourse+fiche.interets_a_payer)
            this.totaux.reste_a_payer[3]+=Number(fiche.capital_restant_du)
            this.totaux.reste_a_payer_remboursement_anticipe[3]+=Number(fiche.reste_a_payer_remboursement_anticipe)
          }
          this.totaux.total_remboursement[4]+=Number(fiche.capital_rembourse+fiche.interets_a_payer)
          this.totaux.reste_a_payer[4]+=Number(fiche.capital_restant_du)
          this.totaux.reste_a_payer_remboursement_anticipe[4]+=Number(fiche.reste_a_payer_remboursement_anticipe)
          
        })
          
        
      }
    }
  },
  created() {
    this.loadBanques()
    this.parametre.date_debut = this.year.annee_debut
    this.parametre.annee_filtre = this.year.annee_debut
    this.parametre.date_fin = this.year.annee_fin
    console.log(this.parametre)
    this.getFicheTechnique(this.parametre)
  },
  computed: {
    ...mapGetters(["ficheTechnique","msgFailFicheTechnique","banques"])
  },
  methods: {
    ...mapActions(["getFicheTechnique","loadBanques"]),
    ...mapMutations(["setMsgFailFicheTechnique","setFicheTechnique",]),
    research(){
      this.setFicheTechnique("")
      this.getFicheTechnique(this.parametre)
    }
  }
}
</script>
