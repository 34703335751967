<template>
  <div :class="taille">
    <div class="row mt-3">
      <div class="parts"
           :class="col_sizing.part1">
        <table class="table">
          <thead>
            
            <tr>
              <th rowspan="3"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;width: 20%;">Banques</th>
              <th rowspan="3"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;">Montants</th>
              <th rowspan="3"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;width: 16%;">Date de mise en place</th>
              <th rowspan="3"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;width: 16%;">Ressources gagées</th>
              <th rowspan="3"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;"
                  v-if="syntheseGenerale.donnees">Remboursements effectués de {{ syntheseGenerale.donnees[0].date_remboursement_de }}</th>
              <th rowspan="3"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;"
                  v-else>Remboursements effectués de</th>
              <th rowspan="3"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;"
                  v-if="syntheseGenerale.donnees">Remboursements effectués du {{ syntheseGenerale.donnees?syntheseGenerale.donnees[0].date_remboursement_du:"" }}</th>
              <th rowspan="3"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: center;"
                  v-else>Remboursements effectués du</th>
              <th class="tht-blue"
                  style="border-bottom: none;"></th>
            </tr>
            <tr>
              <th class="tht-blue"
                  style="border: none;"></th>
            </tr>
            <tr>
              <th class="tht-blue"
                  style="border-top: none; width: 1%;"></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr v-for="(synthese, skey) in syntheseGenerale.donnees"
                :key="skey">
              <td scope="row">{{ synthese.banque }}</td>
              <td>{{ Math.round(Number(synthese.montant_emprunt)).toLocaleString() }}</td>
              <td>{{ synthese.date_debut }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr> -->
            <!-- SIEGE -->
            <template v-for="(synthese, skey) in syntheseGenerale.donnees">
              <tr v-if="synthese.base=='SIEGE'"
                  :key="skey">
                <td scope="row">{{ synthese.banque }}</td>
                <td>{{ Math.round(Number(synthese.montant_emprunt)).toLocaleString() }}</td>
                <td class="text-center">{{ synthese.date_debut }}</td>
                <td>{{ synthese.ressources_gagees.substr(0,15) }}...</td>
                <td class="text-right">{{ Math.round(synthese.Remboursements_effectues_de).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(synthese.Remboursements_effectues_du).toLocaleString() }}</td>
                <td></td>
              </tr>
            </template>
            <tr>
              <td scope="row"
                  class="tht-dark-blue">Sous-Total Siège</td>
              <td class="tht-dark-blue text-center">-</td>
              <td colspan="2"
                  class="tht-dark-blue text-center">-</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.siege.remboursement_de).toLocaleString()}}</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.siege.remboursement_du).toLocaleString()}}</td>
              <td class="tht-dark-blue"></td>
            </tr>
            <!-- PEAGE -->
            <template v-for="(synthese, skey) in syntheseGenerale.donnees">
              <tr v-if="synthese.base=='PEAGE'"
                  :key="skey">
                <td scope="row">{{ synthese.banque }}</td>
                <td>{{ Math.round(Number(synthese.montant_emprunt)).toLocaleString() }}</td>
                <td class="text-center">{{ synthese.date_debut }}</td>
                <td>{{ synthese.ressources_gagees.substr(0,15) }}...</td>
                <td class="text-right">{{ Math.round(synthese.Remboursements_effectues_de).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(synthese.Remboursements_effectues_du).toLocaleString() }}</td>
                <td></td>
              </tr>
            </template>
            <tr>
              <td scope="row"
                  class="tht-dark-blue">Sous-Total Péage</td>
              <td class="tht-dark-blue text-center">-</td>
              <td colspan="2"
                  class="tht-dark-blue text-center">-</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.peage.remboursement_de).toLocaleString()}}</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.peage.remboursement_du).toLocaleString()}}</td>
              <td class="tht-dark-blue"></td>
            </tr>
            <!-- PESAGE -->
            <template v-for="(synthese, skey) in syntheseGenerale.donnees">
              <tr v-if="synthese.base=='PESAGE'"
                  :key="skey">
                <td scope="row">{{ synthese.banque }}</td>
                <td>{{ Math.round(Number(synthese.montant_emprunt)).toLocaleString() }}</td>
                <td class="text-center">{{ synthese.date_debut }}</td>
                <td>{{ synthese.ressources_gagees.substr(0,15) }}...</td>
                <td class="text-right">{{ Math.round(synthese.Remboursements_effectues_de).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(synthese.Remboursements_effectues_du).toLocaleString() }}</td>
                <td></td>
              </tr>
            </template>
            <tr>
              <td scope="row"
                  class="tht-dark-blue">Sous-Total Pésage</td>
              <td class="tht-dark-blue text-center">-</td>
              <td colspan="2"
                  class="tht-dark-blue text-center">-</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.pesage.remboursement_de).toLocaleString()}}</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.pesage.remboursement_du).toLocaleString()}}</td>
              <td class="tht-dark-blue"></td>
              
            </tr>
            <!-- AUTRE -->
            <template v-for="(synthese, skey) in syntheseGenerale.donnees">
              <tr v-if="synthese.base=='AUTRE'"
                  :key="skey">
                <td scope="row">{{ synthese.banque }}</td>
                <td>{{ Math.round(Number(synthese.montant_emprunt)).toLocaleString() }}</td>
                <td class="text-center">{{ synthese.date_debut }}</td>
                <td>{{ synthese.ressources_gagees.substr(0,15) }}...</td>
                <td class="text-right">{{ Math.round(synthese.Remboursements_effectues_de).toLocaleString() }}</td>
                <td class="text-right">{{ Math.round(synthese.Remboursements_effectues_du).toLocaleString() }}</td>
                <td></td>
              </tr>
            </template>
            <tr>
              <td scope="row"
                  class="tht-dark-blue">Sous-Total Autre</td>
              <td class="tht-dark-blue text-center">-</td>
              <td colspan="2"
                  class="tht-dark-blue text-center">-</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.autre.remboursement_de).toLocaleString()}}</td>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.autre.remboursement_du).toLocaleString()}}</td>
              <td class="tht-dark-blue"></td>
              
            </tr>
            <!-- GENERAL -->
            <tr>
              <td scope="row"
                  class="tht-blue">Total général</td>
              <td class="tht-blue text-center">-</td>
              <td colspan="2"
                  class="tht-blue text-center">-</td>
              <td class="tht-blue text-right">{{Math.round(totaux.general.remboursement_de).toLocaleString()}}</td>
              <td class="tht-blue text-right">{{Math.round(totaux.general.remboursement_du).toLocaleString()}}</td>
              <td class="tht-blue"></td>
              
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Partie Dynamique -->
      <div class="parts"
           :class="col_sizing.part2">
        <table class="table">
          <thead>
            <tr>
              <th :colspan="(duree)*3"
                  class="text-center tht-blue">Reste à rembourser du {{ year.periode }}</th>
            </tr>
            <tr>
              <th colspan="3"
                  class="text-center tht-blue"
                  v-for="(counter,cokey) in duree"
                  :key="cokey">{{counter==1 ? annee_debut[0]: Number(annee_debut[0]) + (counter-1)}}</th>
            </tr>
            <tr>
              <template v-for="(counter,cokey) in duree">
                <th class="tht-blue text-right"
                    :key="'prin'+cokey">Principal</th>
                <th class="tht-blue text-right"
                    :key="'in'+cokey">Intérêts TTC</th>
                <th class="tht-blue text-right"
                    :key="'to'+cokey">Total</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <!-- SIEGE -->
            <template v-for="(donnees,dokey) in syntheseGenerale.donnees">
              <tr :key="dokey"
                  v-if="donnees.base=='SIEGE'">
                <template v-for="i in donnees.periode[0].length">
                  <td scope="row"
                      :key="'prin'+i"
                      class="text-right">{{ Math.round(donnees.montant_rembourse_principal[0][i-1]).toLocaleString() }}</td>
                  <td :key="'int'+i"
                      class="text-right">{{Math.round(donnees.montant_rembourse_interet[0][i-1]).toLocaleString()}}</td>
                  <td :key="'tot'+i"
                      class="text-right">{{Math.round(donnees.montant_rembourse_principal[0][i-1]+donnees.montant_rembourse_interet[0][i-1]).toLocaleString()}}</td>
                </template>
              </tr>
            </template>
            <tr>
              <template v-for="i in totaux.siege.montant_rembourse_interet.length">
                <td scope="row"
                    :key="'prin'+i"
                    class="text-right tht-dark-blue">{{ Math.round(totaux.siege.montant_rembourse_principal[i-1]).toLocaleString() }}</td>
                <td :key="'int'+i"
                    class="text-right tht-dark-blue">{{Math.round(totaux.siege.montant_rembourse_interet[i-1]).toLocaleString()}}</td>
                <td :key="'tot'+i"
                    class="text-right tht-dark-blue">{{Math.round(totaux.siege.montant_rembourse_principal[i-1] + totaux.siege.montant_rembourse_interet[i-1]).toLocaleString()}}</td>
              </template>
            </tr>
            <!--/ SIEGE -->
            <!-- PEAGE -->
            <template v-for="(donnees,dokey) in syntheseGenerale.donnees">
              <tr :key="dokey"
                  v-if="donnees.base=='PEAGE'">
                <template v-for="i in donnees.periode[0].length">
                  <td scope="row"
                      :key="'prin'+i"
                      class="text-right">{{ Math.round(donnees.montant_rembourse_principal[0][i-1]).toLocaleString() }}</td>
                  <td :key="'int'+i"
                      class="text-right">{{Math.round(donnees.montant_rembourse_interet[0][i-1]).toLocaleString()}}</td>
                  <td :key="'tot'+i"
                      class="text-right">{{Math.round(donnees.montant_rembourse_principal[0][i-1]+donnees.montant_rembourse_interet[0][i-1]).toLocaleString()}}</td>
                </template>
              </tr>
            </template>
            <tr>
              <template v-for="i in totaux.peage.montant_rembourse_interet.length">
                <td scope="row"
                    :key="'prin'+i"
                    class="text-right tht-dark-blue">{{ Math.round(totaux.peage.montant_rembourse_principal[i-1]).toLocaleString() }}</td>
                <td :key="'int'+i"
                    class="text-right tht-dark-blue">{{Math.round(totaux.peage.montant_rembourse_interet[i-1]).toLocaleString()}}</td>
                <td :key="'tot'+i"
                    class="text-right tht-dark-blue">{{Math.round(totaux.peage.montant_rembourse_principal[i-1]+totaux.peage.montant_rembourse_interet[i-1]).toLocaleString()}}</td>
              </template>
            </tr>
            <!-- /PEAGE -->
            <!-- PESAGE -->
            <template v-for="(donnees,dokey) in syntheseGenerale.donnees">
              <tr :key="dokey"
                  v-if="donnees.base=='PESAGE'">
                <template v-for="i in donnees.periode[0].length">
                  <td scope="row"
                      :key="'prin'+i"
                      class="text-right">{{ Math.round(donnees.montant_rembourse_principal[0][i-1]).toLocaleString() }}</td>
                  <td :key="'int'+i"
                      class="text-right">{{Math.round(donnees.montant_rembourse_interet[0][i-1]).toLocaleString()}}</td>
                  <td :key="'tot'+i"
                      class="text-right">{{Math.round(donnees.montant_rembourse_principal[0][i-1]+donnees.montant_rembourse_interet[0][i-1]).toLocaleString()}}</td>
                </template>
              </tr>
            </template>
            <tr>
              <template v-for="i in totaux.pesage.montant_rembourse_interet.length">
                <td scope="row"
                    :key="'prin'+i"
                    class="text-right tht-dark-blue">{{ Math.round(totaux.pesage.montant_rembourse_principal[i-1]).toLocaleString() }}</td>
                <td :key="'int'+i"
                    class="text-right tht-dark-blue">{{Math.round(totaux.pesage.montant_rembourse_interet[i-1]).toLocaleString()}}</td>
                <td :key="'tot'+i"
                    class="text-right tht-dark-blue">{{Math.round(totaux.pesage.montant_rembourse_principal[i-1]+totaux.pesage.montant_rembourse_interet[i-1]).toLocaleString()}}</td>
              </template>
            </tr>
            <!-- /PESAGE -->
            <!-- AUTRE -->
            <template v-for="(donnees,dokey) in syntheseGenerale.donnees">
              <tr :key="dokey"
                  v-if="donnees.base=='AUTRE'">
                <template v-for="i in donnees.periode[0].length">
                  <td scope="row"
                      :key="'prin'+i"
                      class="text-right">{{ Math.round(donnees.montant_rembourse_principal[0][i-1]).toLocaleString() }}</td>
                  <td :key="'int'+i"
                      class="text-right">{{Math.round(donnees.montant_rembourse_interet[0][i-1]).toLocaleString()}}</td>
                  <td :key="'tot'+i"
                      class="text-right">{{Math.round(donnees.montant_rembourse_principal[0][i-1]+donnees.montant_rembourse_interet[0][i-1]).toLocaleString()}}</td>
                </template>
              </tr>
            </template>
            <tr>
              <template v-for="i in totaux.autre.montant_rembourse_interet.length">
                <td scope="row"
                    :key="'prin'+i"
                    class="text-right tht-dark-blue">{{ Math.round(totaux.autre.montant_rembourse_principal[i-1]).toLocaleString() }}</td>
                <td :key="'int'+i"
                    class="text-right tht-dark-blue">{{Math.round(totaux.autre.montant_rembourse_interet[i-1]).toLocaleString()}}</td>
                <td :key="'tot'+i"
                    class="text-right tht-dark-blue">{{Math.round(totaux.autre.montant_rembourse_principal[i-1] + totaux.autre.montant_rembourse_interet[i-1]).toLocaleString()}}</td>
              </template>
            </tr>
            <!-- /AUTRE -->
            <!-- GENERAL -->
            <tr>
              <template v-for="i in totaux.general.montant_rembourse_interet.length">
                <td scope="row"
                    :key="'prin'+i"
                    class="text-right tht-blue">{{ Math.round(totaux.general.montant_rembourse_principal[i-1]).toLocaleString() }}</td>
                <td :key="'int'+i"
                    class="text-right tht-blue">{{Math.round(totaux.general.montant_rembourse_interet[i-1]).toLocaleString()}}</td>
                <td :key="'tot'+i"
                    class="text-right tht-blue">{{Math.round(totaux.general.montant_rembourse_principal[i-1]+totaux.general.montant_rembourse_interet[i-1]).toLocaleString()}}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Partie des totaux -->
      <div class="parts"
           :class="col_sizing.part3">
        <table class="table">
          <thead>
            <tr>
              <th rowspan="3"
                  class="tht-blue"
                  style="vertical-align: middle;text-align: right;">
                Totaux ({{ year.periode }})</th>
              <th class="tht-blue"
                  style="border-bottom: none; width: 0.1%;"></th>
            </tr>
            <tr>
              <th style="border: none; width: 0.1%;"
                  class="tht-blue"></th>
            </tr>
            <tr>
              <th style="border-top: none; width: 0.1%;"
                  class="tht-blue"></th>
            </tr>
          </thead>
          <tbody>
            <!-- SIEGE -->
            <template v-for="(total,tokey) in syntheseGenerale.donnees">
              <tr v-if="total.base=='SIEGE'"
                  :key="tokey">
                <td scope="row"
                    class="text-right">{{ Math.round(total.total_remboursement).toLocaleString() }}</td>
                <td style="width: 0.1%;"></td>
              </tr>
            </template>
            <tr>
              <td class="tht-dark-blue text-right">{{totaux.siege.total}}</td>
              <td class="tht-dark-blue"></td>
            </tr>
            <!-- PEAGE -->
            <template v-for="(total,tokey) in syntheseGenerale.donnees">
              <tr v-if="total.base=='PEAGE'"
                  :key="tokey">
                <td scope="row"
                    class="text-right">{{ Math.round(total.total_remboursement).toLocaleString() }}</td>
                <td style="width: 0.1%;"></td>
              </tr>
            </template>
            <tr>
              <td class="tht-dark-blue text-right">{{totaux.peage.total}}</td>
              <td class="tht-dark-blue"></td>
            </tr>
            <!-- PEAGE -->
            <template v-for="(total,tokey) in syntheseGenerale.donnees">
              <tr v-if="total.base=='PESAGE'"
                  :key="tokey">
                <td scope="row"
                    class="text-right">{{ Math.round(total.total_remboursement).toLocaleString() }}</td>
                <td style="width: 0.1%;"></td>
              </tr>
            </template>
            <tr>
              <td class="tht-dark-blue text-right">{{totaux.pesage.total}}</td>
              <td class="tht-dark-blue"></td>
            </tr>
            <!-- AUTRE -->
            <template v-for="(total,tokey) in syntheseGenerale.donnees">
              <tr v-if="total.base=='AUTRE'"
                  :key="tokey">
                <td scope="row"
                    class="text-right">{{ Math.round(total.total_remboursement).toLocaleString() }}</td>
                <td style="width: 0.1%;"></td>
              </tr>
            </template>
            <tr>
              <td class="tht-dark-blue text-right">{{Math.round(totaux.autre.total).toLocaleString()}}</td>
              <td class="tht-dark-blue"></td>
            </tr>
            <!-- GENERAL -->
            <tr>
              <td class="tht-blue text-right">{{Math.round(totaux.general.total).toLocaleString()}}</td>
              <td class="tht-blue"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-3">
      <div class="mx-auto"
           :class="col_sizing.part4">
        <table class="table">
          <thead>
            <tr>
              <th class="tht-blue text-center"
                  colspan="2">Reste à payer au</th>
              <th class="tht-blue text-center">SIEGE</th>
              <th class="tht-blue text-center">PEAGE</th>
              <th class="tht-blue text-center">PESAGE</th>
              <th class="tht-blue text-center">AUTRE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="tht-blue"
                  style="width: 10%;">TOTAL</td>
              <td class="text-right"
                  v-for="(total,totkey) in recapitulatif.total"
                  :key="totkey">{{ Math.round(total).toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="tht-blue">CAPITAL</td>
              <td class="text-right"
                  v-for="(total,totkey) in recapitulatif.capital"
                  :key="totkey">{{ Math.round(total).toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="tht-blue">INTERET</td>
              <td class="text-right"
                  v-for="(total,totkey) in recapitulatif.interet"
                  :key="totkey">{{ Math.round(total).toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>
@import '../leveeDeFonds.css';
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import utils from "@/assets/js/utils"

export default {
  name: "SyntheseGenerale",
  props:["year"],
  components:{
  },
  data: () => ({
    taille:"chisai",
    duree:0,
    annee_debut: new Date().getFullYear(),
    annee_fin: new Date().getFullYear(),
    col_sizing:{
      part1:"col-md-7",
      part2:"col-md-4",
      part3:"col-md-1",
      part4:"col-md-8"
    },
    totaux:{
      siege:{remboursement_de: 0, remboursement_du: 0, montant_rembourse_interet:[], montant_rembourse_principal:[], total:0},
      peage:{remboursement_de: 0, remboursement_du: 0, montant_rembourse_interet:[], montant_rembourse_principal:[], total:0},
      pesage:{remboursement_de: 0, remboursement_du: 0, montant_rembourse_interet:[], montant_rembourse_principal:[], total:0},
      autre:{remboursement_de: 0, remboursement_du: 0, montant_rembourse_interet:[], montant_rembourse_principal:[], total:0},
      general:{remboursement_de: 0, remboursement_du: 0, montant_rembourse_interet:[], montant_rembourse_principal:[], total:0}
    },
    recapitulatif:{
      total:[0,0,0,0,0],
      capital:[0,0,0,0,0],
      interet:[0,0,0,0,0]
    }
  }),
  watch: {
    syntheseGenerale(){
      if (this.syntheseGenerale) {

        if (this.syntheseGenerale.donnees[0].montant_rembourse_interet[0].length > 1 && this.syntheseGenerale.donnees[0].montant_rembourse_interet[0].length <= 5) {
          this.taille="heikin"
          this.col_sizing.part1="col-md-4"
          this.col_sizing.part2="col-md-7"
          this.col_sizing.part3="col-md-1"
          this.col_sizing.part4="col-md-3"
        }else{
          if (this.syntheseGenerale.donnees[0].montant_rembourse_interet[0].length > 5) {
            this.taille="ooki"
            this.col_sizing.part1="col-md-2"
            this.col_sizing.part2="col-md-9"
            this.col_sizing.part3="col-md-1"
            this.col_sizing.part4="col-md-2"
          }
        }
        this.totaux={
          siege:{remboursement_de: 0, remboursement_du: 0, montant_rembourse_interet:[], montant_rembourse_principal:[], total:0},
          peage:{remboursement_de: 0, remboursement_du: 0, montant_rembourse_interet:[], montant_rembourse_principal:[], total:0},
          pesage:{remboursement_de: 0, remboursement_du: 0, montant_rembourse_interet:[], montant_rembourse_principal:[], total:0},
          autre:{remboursement_de: 0, remboursement_du: 0, montant_rembourse_interet:[], montant_rembourse_principal:[], total:0},
          general:{remboursement_de: 0, remboursement_du: 0, montant_rembourse_interet:[], montant_rembourse_principal:[], total:0}
        }   
        
        for (let index = 0; index < this.syntheseGenerale.donnees[0].montant_rembourse_interet[0].length; index++) {
          this.totaux.siege.montant_rembourse_interet.push(0)
          this.totaux.peage.montant_rembourse_interet.push(0)
          this.totaux.pesage.montant_rembourse_interet.push(0)
          this.totaux.autre.montant_rembourse_interet.push(0)
          this.totaux.general.montant_rembourse_interet.push(0)
          this.totaux.siege.montant_rembourse_principal.push(0)
          this.totaux.peage.montant_rembourse_principal.push(0)
          this.totaux.pesage.montant_rembourse_principal.push(0)
          this.totaux.autre.montant_rembourse_principal.push(0)
          this.totaux.general.montant_rembourse_principal.push(0)
        }

        this.syntheseGenerale.donnees.forEach(synthese => {
          console.log(synthese.Remboursements_effectues_de)
          if (synthese.base=='SIEGE') {
            for (let index = 0; index < synthese.montant_rembourse_interet[0].length; index++) {
              this.totaux.siege.montant_rembourse_interet[index] += synthese.montant_rembourse_interet[0][index]
              this.totaux.siege.montant_rembourse_principal[index] += synthese.montant_rembourse_principal[0][index]
              
              this.totaux.general.montant_rembourse_interet[index] += synthese.montant_rembourse_interet[0][index]
              this.totaux.general.montant_rembourse_principal[index] += synthese.montant_rembourse_principal[0][index]
            }
            this.totaux.siege.remboursement_de += synthese.Remboursements_effectues_de
            this.totaux.siege.remboursement_du += synthese.Remboursements_effectues_du
              
            this.totaux.general.remboursement_de += synthese.Remboursements_effectues_de
            this.totaux.general.remboursement_du += synthese.Remboursements_effectues_du
            this.totaux.siege.total += synthese.total_remboursement
            this.totaux.general.total += synthese.total_remboursement
          }else{
            if (synthese.base=='PEAGE') {
              for (let index = 0; index < synthese.montant_rembourse_interet[0].length; index++) {
                this.totaux.peage.montant_rembourse_interet[index] += synthese.montant_rembourse_interet[0][index]
                this.totaux.peage.montant_rembourse_principal[index] += synthese.montant_rembourse_principal[0][index]
                
                this.totaux.general.montant_rembourse_interet[index] += synthese.montant_rembourse_interet[0][index]
                this.totaux.general.montant_rembourse_principal[index] += synthese.montant_rembourse_principal[0][index]
              }
              this.totaux.peage.remboursement_de += synthese.Remboursements_effectues_de
              this.totaux.peage.remboursement_du += synthese.Remboursements_effectues_du

              this.totaux.general.remboursement_de += synthese.Remboursements_effectues_de
              this.totaux.general.remboursement_du += synthese.Remboursements_effectues_du
              this.totaux.peage.total += synthese.total_remboursement
              this.totaux.general.total += synthese.total_remboursement
 
            }else{
              if (synthese.base=='PESAGE') {
                for (let index = 0; index < synthese.montant_rembourse_interet[0].length; index++) {
                  this.totaux.pesage.montant_rembourse_interet[index] += synthese.montant_rembourse_interet[0][index]
                  this.totaux.pesage.montant_rembourse_principal[index] += synthese.montant_rembourse_principal[0][index]

                  this.totaux.general.montant_rembourse_interet[index] += synthese.montant_rembourse_interet[0][index]
                  this.totaux.general.montant_rembourse_principal[index] += synthese.montant_rembourse_principal[0][index]
                }
                this.totaux.pesage.remboursement_de += synthese.Remboursements_effectues_de
                this.totaux.pesage.remboursement_du += synthese.Remboursements_effectues_du

                this.totaux.general.remboursement_de += synthese.Remboursements_effectues_de
                this.totaux.general.remboursement_du += synthese.Remboursements_effectues_du
                this.totaux.pesage.total += synthese.total_remboursement
                this.totaux.general.total += synthese.total_remboursement

              }else{
                for (let index = 0; index < synthese.montant_rembourse_interet[0].length; index++) {
                  this.totaux.autre.montant_rembourse_interet[index] += synthese.montant_rembourse_interet[0][index]
                  this.totaux.autre.montant_rembourse_principal[index] += synthese.montant_rembourse_principal[0][index]

                  this.totaux.general.montant_rembourse_interet[index] += synthese.montant_rembourse_interet[0][index]
                  this.totaux.general.montant_rembourse_principal[index] += synthese.montant_rembourse_principal[0][index]
                }
                this.totaux.autre.remboursement_de += synthese.Remboursements_effectues_de
                this.totaux.autre.remboursement_du += synthese.Remboursements_effectues_du

                this.totaux.general.remboursement_de += synthese.Remboursements_effectues_de
                this.totaux.general.remboursement_du += synthese.Remboursements_effectues_du
                this.totaux.autre.total += synthese.total_remboursement
                this.totaux.general.total += synthese.total_remboursement
              }
            }
          }
        })
        console.log(this.totaux)
        this.recapitulatif={
          total:[0,0,0,0,0],
          capital:[0,0,0,0,0],
          interet:[0,0,0,0,0]
        }
        for (let index = 0; index < this.totaux.general.montant_rembourse_interet.length; index++) {
          // TOTAL
          this.recapitulatif.total[0]+=this.totaux.general.montant_rembourse_interet[index]+this.totaux.general.montant_rembourse_principal[index]
          this.recapitulatif.total[1]+=this.totaux.siege.montant_rembourse_interet[index]+this.totaux.siege.montant_rembourse_principal[index]
          this.recapitulatif.total[2]+=this.totaux.peage.montant_rembourse_interet[index]+this.totaux.peage.montant_rembourse_principal[index]
          this.recapitulatif.total[3]+=this.totaux.pesage.montant_rembourse_interet[index]+this.totaux.pesage.montant_rembourse_principal[index]
          this.recapitulatif.total[4]+=this.totaux.autre.montant_rembourse_interet[index]+this.totaux.autre.montant_rembourse_principal[index]
          
          // CAPITAL
          this.recapitulatif.capital[0]+=this.totaux.general.montant_rembourse_principal[index]
          this.recapitulatif.capital[1]+=this.totaux.siege.montant_rembourse_principal[index]
          this.recapitulatif.capital[2]+=this.totaux.peage.montant_rembourse_principal[index]
          this.recapitulatif.capital[3]+=this.totaux.pesage.montant_rembourse_principal[index]
          this.recapitulatif.capital[4]+=this.totaux.autre.montant_rembourse_principal[index]

          // INTERET
          this.recapitulatif.interet[0]+=this.totaux.general.montant_rembourse_interet[index]
          this.recapitulatif.interet[1]+=this.totaux.siege.montant_rembourse_interet[index]
          this.recapitulatif.interet[2]+=this.totaux.peage.montant_rembourse_interet[index]
          this.recapitulatif.interet[3]+=this.totaux.pesage.montant_rembourse_interet[index]
          this.recapitulatif.interet[4]+=this.totaux.autre.montant_rembourse_interet[index]
        }
        console.log(this.recapitulatif)
      }
    }
  },
  created() {
    this.annee_fin= this.year.annee_fin.split("-")
    this.annee_debut= this.year.annee_debut.split("-")
    
    this.duree=(Number(this.annee_fin[0]) - Number(this.annee_debut[0])) + 1
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.listExtensionFiles = utils.formaFichierAccepte
    this.getSyntheseGenerale(this.year)
  },
  computed: {
    ...mapGetters(["syntheseGenerale","msgFailSyntheseGenerale",])
  },
  methods: {
    ...mapActions(["getSyntheseGenerale"]),
    ...mapMutations(["setMsgFailSyntheseGenerale","setSyntheseGenerale",]),
    
  }
}
</script>
